import { Box, Link } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { useSplitTrack } from '../../../split.io';
import { FooterContainer } from './FooterContainer';

const wwwUrl = process.env.NEXT_PUBLIC_WWW_URL;

const DEFAULT_UTM_URL_PARAMS =
  'utm_campaign=powered%20by%20mentimeter%20button&utm_medium=web-link&utm_source=govote';

interface WebFooterProps {
  utmUrlParams?: string | undefined;
  onMentimeterLinkClick?: (() => void) | undefined;
  context?: 'Home' | 'FinishScreen' | undefined;
}

export const DefaultFooter = ({
  utmUrlParams = DEFAULT_UTM_URL_PARAMS,
  onMentimeterLinkClick,
  context,
}: WebFooterProps) => {
  const trackSplit = useSplitTrack();

  const handleMentimeterLinkClick = () => {
    trackSplit('Clicked_through_to_mentimeter');
    onMentimeterLinkClick?.();
  };

  return (
    <>
      <FooterContainer.Text mb="space6">
        Create your own Menti at&nbsp;
        <Link
          href={`${wwwUrl}/auth/justvoted?${utmUrlParams}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleMentimeterLinkClick}
        >
          mentimeter.com
        </Link>
      </FooterContainer.Text>
      {context !== 'FinishScreen' && (
        <Box
          width="100%"
          bg="surfaceSunken"
          height="48px"
          alignItems="center"
          justifyContent="center"
        >
          <FooterContainer.TermsText fontSize="75">
            By using Mentimeter you accept our{' '}
            <FooterContainer.TermsOfUseLink>
              terms of use
            </FooterContainer.TermsOfUseLink>{' '}
            and{' '}
            <FooterContainer.PoliciesLink>
              policies
            </FooterContainer.PoliciesLink>
            .
          </FooterContainer.TermsText>
        </Box>
      )}
    </>
  );
};
