import { Box } from '@mentimeter/ragnar-ui';
import { addUnit } from '@mentimeter/ragnar-utils';
import * as React from 'react';
import Footer from '../Components/Footer/Footer';

export interface PropsT {
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const PageLayout = ({ children, footer = <Footer /> }: PropsT) => {
  return (
    <Box
      width="100%"
      maxWidth={['100vw', '568px']}
      flex="1 1 auto"
      alignItems="stretch"
      pt={['space8', 'space16']}
      m="0 auto"
      extend={({ theme }) => ({
        '@media screen and (max-width: 352px)': {
          paddingLeft: addUnit(theme.kosmosSpacing.space1),
          paddingRight: addUnit(theme.kosmosSpacing.space1),
          width: '272px',
        },
      })}
    >
      <Box px="space10" flex="1 0 auto" alignItems="center" as="main">
        {children}
      </Box>

      {/* Static footer */}
      {Boolean(footer) && (
        <Box justifyContent="center" width="100%">
          {footer}
        </Box>
      )}
    </Box>
  );
};
