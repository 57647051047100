import * as React from 'react';
import { Button, type ButtonT } from '@mentimeter/ragnar-ui';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import { useSplitTrack, useSplitTreatments } from '../../split.io';
import { useTranslate } from '../../features/localization/Translate';

export interface NavigationButtonProps extends ButtonT {
  onClick: React.MouseEventHandler<Element>;
  isLastSlide?: boolean;
}

export const NavigationButtonExperiment = ({
  onClick,
  isLastSlide = true,
}: NavigationButtonProps) => {
  const trackSplit = useSplitTrack();
  const { OG_Menti_Finish_Button_To_Endscreen } = useSplitTreatments([
    'OG_Menti_Finish_Button_To_Endscreen',
  ]);
  const treatment = OG_Menti_Finish_Button_To_Endscreen?.treatment || 'v1';
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    trackSplit('PostVote_Screen.Button_clicked.Finish');
    onClick(e);
  };

  if (['v2', 'v3'].includes(treatment)) {
    return (
      <Button
        size="large"
        variant="primary"
        width={1}
        mt="space4"
        onClick={onClickHandler}
      >
        {treatment === 'v2' ? 'Continue' : 'Next'}
      </Button>
    );
  }

  return (
    <NavigationButton onClick={onClickHandler} isLastSlide={isLastSlide} />
  );
};

export const NavigationButton = ({
  onClick,
  isLastSlide = true,
}: NavigationButtonProps) => {
  const translate = useTranslate();

  return (
    <Button
      size="large"
      iconTrailing={ChevronRightIcon}
      variant="subtle"
      mt="space4"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {isLastSlide ? translate('buttons.finish') : translate('buttons.next')}
    </Button>
  );
};
