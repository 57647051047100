import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import React from 'react';
import { LocalStorage } from '@mentimeter/storage';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui';
import { useIdentifier } from '../utils/useIdentifier';

const SPLIT_BROWSER_API_KEY = process.env
  .NEXT_PUBLIC_SPLIT_BROWSER_API_KEY as string;

const SplitSetup = ({
  children,
  identifier,
}: React.PropsWithChildren<{ identifier: string }>) => {
  // Needs to be memoized to avoid re-initalizing the SDK
  const config: SplitIO.IBrowserSettings = React.useMemo(
    () => ({
      // To override the treatment of an experiment for development
      // purposes, set the value of core.authorizationKey to 'localhost'
      // and add a features object, with the treatment you want,
      // like below 👇. Note that this will set all other experiments to 'control'.
      // features: { Your_Experiment: 'on' },
      debug: false,
      core: {
        key: identifier,
        authorizationKey: SPLIT_BROWSER_API_KEY,
        trafficType: 'voter',
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
      scheduler: {
        eventsPushRate: 1,
        impressionsRefreshRate: 1,
      },
    }),
    [identifier],
  );

  return (
    <SplitFactoryProvider config={config} updateOnSdkTimedout>
      {children}
    </SplitFactoryProvider>
  );
};

const Loading = () => {
  return (
    <Box
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg="bg"
      p={3}
    >
      <LoaderIcon />
    </Box>
  );
};

export const SplitProvider = ({ children }: React.PropsWithChildren) => {
  const identifier = useIdentifier();

  // This 3 condtions (isNode, typeof localStorage === 'undefined', !identifier)

  // The React split SDK cannot be run server side
  if (typeof window === 'undefined') return children;

  // If we don't have access to localStorage, Split does not work
  if (!LocalStorage.isSupported()) return children;

  // we don't want to assign voters to treatments before they've received their identifier
  if (!identifier) return <Loading />;

  return <SplitSetup identifier={identifier}>{children}</SplitSetup>;
};
