'use client';

import { MentiError, captureException } from '@mentimeter/errors/sentry';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { isServer } from '@mentimeter/detect-server';
import type { ClientBaseT } from '../createClientCreator';
import { exposeDefaultClientApi, defaultHeaders } from '../createClientCreator';
import { handleNetworkError } from '../networkErrorIntercept';
import type { VotingRequests } from './types';

export type VotingClient = ClientBaseT<VotingRequests>;

export const createVotingClient = ({
  baseURL,
  getIdentifier,
  extraHeaders,
  httpAgent,
  httpsAgent,
  timeout = 0,
}: {
  baseURL: string;
  getIdentifier: () => Promise<string | null>;
  httpAgent?: any;
  httpsAgent?: any;
  timeout?: number;
  extraHeaders?: AxiosRequestConfig['headers'];
}): ClientBaseT<VotingClient> => {
  if (!baseURL) {
    throw new Error('missing baseURL in createVotingClient');
  }

  if (!getIdentifier) {
    throw new Error('missing getIdentifier in createVotingClient');
  }

  const client = axios.create({
    baseURL,
    headers: {
      ...defaultHeaders,
      ...extraHeaders,
    },
    timeout,
  });

  if (httpAgent) {
    client.defaults.httpAgent = httpAgent;
  }
  if (httpsAgent) {
    client.defaults.httpsAgent = httpsAgent;
  }

  const requestInterceptor = async (config: AxiosRequestConfig) => {
    if (isServer) {
      return config;
    }
    // Attach identifier to header
    try {
      const identifier = await getIdentifier();
      if (identifier && config.headers) {
        config.headers['X-Identifier'] = identifier;
      }
    } catch (err: any) {
      captureException(
        new MentiError('getIdentifier failed in interceptor', {
          feature: 'api',
          cause: err,
        }),
      );
    }
    return config;
  };

  const requestInterceptorErrorHandler = (error: any) => {
    return Promise.reject(error);
  };

  // Intercept network requests to handle headers
  client.interceptors.request.use(
    requestInterceptor,
    requestInterceptorErrorHandler,
  );

  // Intercept network response errors to decide whether they should be sent to sentry or datadog
  client.interceptors.response.use((response) => response, handleNetworkError);

  return {
    ...exposeDefaultClientApi(client),
    series: {
      getById(voteId, config) {
        return client.get(`/audience/series/${voteId}/vote-key`, config);
      },
      getByKey(voteKey, config) {
        return client.get(`/audience/series/${voteKey}`, config);
      },
      getSeriesIdByKey(voteKey, config) {
        return client.get(`/audience/series/${voteKey}/series-id`, config);
      },
    },
    vote: {
      post(publicKey, data, config) {
        return client.post(`/audience/responses/${publicKey}`, data, config);
      },
      upvote(publicKey, voteId, data, config) {
        return client.post(
          `/audience/votes/${publicKey}/upvote/${voteId}`,
          data,
          config,
        );
      },
    },
    qfa: {
      post(voteKey, payload, config?) {
        return client.post(`/audience/qfa/${voteKey}`, payload, config);
      },
      upvote(id, config?) {
        return client.post(`/audience/qfa/${id}/upvote`, config);
      },
      undoUpvote(id) {
        return client.delete(`/audience/qfa/${id}/upvote`);
      },
      get(voteKey, page, config) {
        return client.get(`/audience/qfa/${voteKey}`, {
          ...config,
          params: {
            page,
          },
        });
      },
      getByUpvote(voteKey, page, config) {
        return client.get(`/audience/qfa/${voteKey}`, {
          ...config,
          params: {
            sort_by_upvote: true,
            page,
          },
        });
      },
    },
    quiz: {
      async fetchPlayer(voteKey, identifier, tries, config) {
        const logTriesQueryString = tries > 0 ? `?tries=${tries}` : '';
        return client.post(
          `/audience/quiz/${voteKey}/players${logTriesQueryString}`,
          config,
        );
      },
      async updatePlayer(voteKey, data, config) {
        return client.patch(`/audience/quiz/${voteKey}/players`, data, config);
      },
    },
    results: {
      get(publicKey, config) {
        return client.get(`/audience/votes/${publicKey}/results`, config);
      },
    },
    audience: {
      connect(voteKey, isDesktopExperience, config) {
        return client.post(
          `/audience/connect/${voteKey}`,
          { is_desktop_experience: isDesktopExperience },
          config,
        );
      },
      sendDisconnectBeacon(voteKey, identifier) {
        if (!navigator.sendBeacon) return false;
        // In order to set the 'Content-Type' header to 'application/json', we
        // need to create a blob that contains that metadata. Beacon API supports
        // no other headers.
        const blob = new Blob([JSON.stringify({ identifier })], {
          type: 'application/json',
        });
        return navigator.sendBeacon(
          `${client.defaults.baseURL}/audience/disconnect/${voteKey}`,
          blob,
        );
      },
      identity: {
        get(voteKey, config) {
          return client.get(`/audience/identity/${voteKey}`, config);
        },
        post(voteKey, data, config) {
          return client.post(
            `/audience/identity/${voteKey}`,
            { display_name: data.displayName },
            config,
          );
        },
      },
      reactions: {
        post(publicKey, data, config) {
          return client.post(`/audience/reactions/${publicKey}`, data, config);
        },
        publish(publicKey, data, config) {
          return client.post(
            `/audience/reactions/${publicKey}/publish`,
            data,
            config,
          );
        },
      },
      comments: {
        publish(publicKey, data, config) {
          return client.post(
            `/audience/comments/${publicKey}/publish`,
            data,
            config,
          );
        },
      },
    },
    groups: {
      get(publicKey, config) {
        return client.get(
          `/response-clusterings/for-question/${publicKey}`,
          config,
        );
      },
    },
  };
};
